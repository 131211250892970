// import 'promise-polyfill/src/polyfill';
// window.Vue = require('vue');
window._ = require('lodash');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'; 

import 'core-js/stable';
// import store from './store';
import tippy from 'tippy.js';
import lightcase from 'lightcase';
import './jquery-eu-cookie-law-popup'; 
import chosen from 'chosen-js';

$(document).ready(function () { 

  $( function() {
    $( ".datepicker" ).datepicker({
      'dateFormat': 'dd/mm/yy'
    });
  } );

  if($('#calculate').length) {
    function round(number, increment, offset) {
      return Math.ceil((number - offset) / increment ) * increment + offset;
    }
    
    const minutes =  round(new Date().getMinutes(),  15, 0); 
  if(moment().set('minute', minutes).format('HH') > 9) {
    $('input[name="date_start_on"]').val(moment().set('minute', 0).add(1, 'hours').format('DD-MM-YYYY HH:mm'));
    $('input[name="date_finish_on"]').val(moment().set('minute', 0).add(1, 'hours').add(5, 'd').format('DD-MM-YYYY HH:mm'));
  } else { 
    $('input[name="date_start_on"]').val(moment().set({'hours': 9, 'minute': 0}).format('DD-MM-YYYY HH:mm'));
    $('input[name="date_finish_on"]').val(moment().set({'hours': 9, 'minute': 0}).add(5, 'd').format('DD-MM-YYYY HH:mm'));
  }
  
  if($('#datapicker_open').length) {
    $('#datapicker_open').daterangepicker({
      "showDropdowns": true,
      "autoApply": true,
      "timePicker": true,
      "timePicker24Hour": true,
      "minDate": (moment().set('minute', 0).format('HH') >= 9) ? moment().set({'minute': 0}).add(1, 'hours').format('DD-MM-YYYY HH:mm') : moment().set({'hours': 9, 'minute': 0}).format('DD-MM-YYYY HH:mm'),
      "timePickerIncrement": 15,
      "startDate": (moment().set('minute', minutes).format('HH') > 9) ? moment().set({'minute': 0}).add(1, 'hours').format('DD-MM-YYYY HH:mm') : moment().set({'hours': 9, 'minute': 0}).format('DD-MM-YYYY HH:mm'),
      "endDate": (moment().set('minute', minutes).format('HH') > 9) ? moment().set({'minute': minutes}).add(5, 'd').format('DD-MM-YYYY HH:mm') : moment().set({'hours': 9, 'minute': 0}).add(5, 'd').format('DD-MM-YYYY HH:mm'),
      "opens": "center",
      isInvalidDate: function(date) {
        if (date.day() != 0 )
          return false;
        return true;
      },
      "locale": {
        "format": "DD-MM-YYYY HH:mm",
        "separator": " - ",
        "applyLabel": "Wybierz",
        "cancelLabel": "Anuluj",
        "fromLabel": "Od",
        "toLabel": "Do",
        "customRangeLabel": "Custom",
        "weekLabel": "W",
        "daysOfWeek": [
            "ND",
            "PON",
            "WT",
            "ŚR",
            "CZ",
            "PT",
            "SO"
        ],
        "monthNames": [
            "Styczeń",
            "Luty",
            "Marzec",
            "Kwiecień",
            "Maj",
            "Czerwiec",
            "Lipiec",
            "Sierpień",
            "Wrzesień",
            "Październik",
            "Listopad",
            "Grudzień"
        ],
        "firstDay": 1
    },
  }, function(start, end, label) {
    $('input[name="date_start_on"]').val(start.format('DD-MM-YYYY HH:mm'));
    $('input[name="date_finish_on"]').val(end.format('DD-MM-YYYY HH:mm'));
  }); 
  }
  
  
  
  
  
  $('#calculate').on('click', function(){
    var date_start = moment($('input[name="date_start_on"]').val(), 'DD/MM/YYYY HH:mm');
    var date_finish = moment($('input[name="date_finish_on"]').val(), 'DD/MM/YYYY HH:mm');
    var between = moment.duration( date_finish.diff(date_start));
  
    var days = Math.round(moment.duration( date_finish.diff(date_start)).asDays()) + 1;
    var hours = between._data.hours;
    // niżej sprawdza czy są jakieś godziny (jeśli tak to dodaje dzień!)
    if(hours > 1) {
      days = days+1;
    }
  
    // console.log('licze dla '+days+'dni')
    var arr = JSON.parse($('#reservation_info').val());
    // console.log(arr)
    var result = null
    function isBigEnough(element) {
      // console.log(element)
      var numbers = element[0].split('-');
  
      if(arr.slice(-1)[0][0].split('-')[1] < days|| numbers[0] == 0) {
          return $('#final_price').html('Wycena indywidualna')
      } else {
        if(numbers.length == 1) {
          // console.log(numbers)
          if(numbers == days) {
            // console.log('ilosc dni: '+days)
            // console.log('kwota dla tej opcji: '+element[1])
            result = days*element[1]
            // console.log('kwota: '+result+'zł')
            return $('#final_price').html(result+'zł')
          }
        } else {
          // 2-7 szukana 5 czyli większe/równe od 2 mniejsze od 7 lub równe
          if(numbers[0] <= days && numbers[1]>= days) {
            // console.log('if')
            // console.log('ilosc dni: '+days)
            // console.log('kwota dla tej opcji: '+element[1])
            result = days*element[1]
            return $('#final_price').html(result+'zł')
          } 
          // else {
          //   console.log('else')
          //   console.log(element)
          //   // console.log(days)
          //   if(numbers[1] < days) {
          //     return $('#final_price').html('Wycena indywidualna')
          //   }
          // }
        }
      } 
    }
    arr.findIndex(isBigEnough);
  })
  }



  $('svg').tooltip()
  $('a[data-rel^=lightcase]').lightcase({
    transition: 'scrollHorizontal',
    maxWidth:'auto',
    maxHeight:'auto',
    caption: ' ',
  }); 

  $('.add_arrow').on('click', function(){
    $(this).toggleClass('open')
  })


  // menu click event
  $('.menuBtn').click(function () {
    $(this).toggleClass('act');
    if ($(this).hasClass('act')) {
      $('.mainMenu').addClass('act');
      $('#header .logo').addClass('open');
      $('body').css('overflow-y','hidden');
    } else {
      $('.mainMenu').removeClass('act');
      $('#header .logo').removeClass('open');
      $('body').css('overflow-y','auto');
      if($('.headroom').hasClass('headroom--top')) { 
      } else {
      }
    }
  });
});









//tooltip default settings
tippy.setDefaults({
  animation: 'shift-away',
  arrow: true,
});

// remove active menu burger
$(document).scroll(function () {

  if ($(document).scrollTop() > 200) {
   $('#navbarNavDropdown').removeClass('show');
  }
});

// Dwie opcje dla menu dropdown hover
var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

if (!supportsTouch && window.innerWidth >= 991) {
  $("li.dropdown").hover(
    function () {
      $(this).addClass('show');
      $(this).children('ul').addClass('show');
    },
    function () {
      $(this).removeClass('show');
      $(this).children('ul').removeClass('show');
    }
  );
};

$(window).resize(function () {
  // w razie gdy powięszy przeglądarkę przy otwartym menu
  if(window.innerWidth > 991) {
    $('.mainMenu').removeClass('act');
    $('.menuBtn').removeClass('act');
    $('body').css('overflow-y','auto');
  }

  if (!supportsTouch && window.innerWidth >= 991) {
    $("li.dropdown").hover(
      function () {
        $(this).addClass('show');
        $(this).children('ul').addClass('show');
      },
      function () {
        $(this).removeClass('show');
        $(this).children('ul').removeClass('show');
      }
    );
  } else {
    $("li.dropdown").unbind('mouseenter mouseleave');
  };  
}); 

//menu gĹĂłwne scroll 
sourceSet();
$(window).resize(function () {
  sourceSet();
});
//podmiana obrazkĂłw w Ofercie prezentowej (wywoĹywane wyĹźej)
function sourceSet() {
  $(".inverse").each(function (index) {
    var mobile = $(this).attr('data-mobile');
    var desktop = $(this).attr('data-desktop');
    if (window.innerWidth <= 991) {
      $(this).attr("src", mobile)
    } else {
      $(this).attr("src", desktop)
    }
  });
}
 
$('.mobile-click').click(function (e) {
  if (window.innerWidth >= 768 ) {
  e.preventDefault();
  return false;
  }
}) 

// Ładowanie komentarzy
var ilosc = $('.comment-content').length;
var show = 3;

$('.show_more_comments').on('click', function () {
  function addPosts() {
    show = show + 3;
    displayComments();
    if (ilosc < show) {
      $('.show_more_comments').css('display', 'none');
    }
  }
  addPosts();
})

function displayComments() {
  $('.comment-content').each(function (i) {
    if (i < show) {
      $(this).css('display', 'block');
    }
  });
  if (ilosc <= show) {
    $('.show_more_comments').css('display', 'none');
  }
}
displayComments();
 
// wymagane pola dla faktury VAT jeĹli jest wybrany
$('#invoice_label').on('change', function () {
  if ($('#invoice_label').prop("checked")) {
    $('#faktura_data input').removeAttr('value');
    $('#faktura_data input').parents('label').removeClass('active')
    $('#faktura_data input').val('') 
    $('#faktura_data input[name="company"], #faktura_data input[name="nip"], #faktura_data input[name="company_postcod"], #faktura_data input[name="company_city"], #faktura_data input[name="company_address"], #faktura_data input[name="company_house_nr"]').attr("validate", "required");
    $('#faktura_data input[name="company"]').attr('min', '2')
    $('#faktura_data input[name="nip"]').attr({'min':'10', 'max':'10'})
  } else { 
    $('#faktura_data input').val('')
    $('#faktura_data input').removeAttr("validate min max");
  }
})

// sprawdza czy pole jest uzupeĹnione
$('input, textarea').each(function () {
  if ($(this).val()) {
    $(this).parents('label').addClass('active')
  }
});  

//rotate chevron 
$('.dropdown-icon').on("click", function () {
  if ($('.collapse').hasClass('show')) {
    $(this).parents('.decoration').removeClass('active');
  } else {
    $(this).parents('.decoration').addClass('active');
  }
});
 

//sprawdzanie czy przeglÄdarka uzupeĹniĹa jesli tak dodaje span na active
$(document).ready(function () {

 

  setTimeout(() => {
    $('input').each(function () { 
      if ($(this).css('animation-name') == 'onAutoFillStart') {
        $(this).parents('label').addClass('active')
      }
    })
  }, 300); 


  $('.edit-data').click(function() { 
    $('.user-data input').prop('disabled', false);
    $('.edit-data').hide();
    $('.save-data').show();
  });
  


  $(".sidebar--offer--custom > li.have-children a.action").on("click", function (i) {
    i.preventDefault();
    if (!$(this).parent().hasClass("active")) {
      $(".sidebar--offer--custom li ul").slideUp();
      $(this).next().slideToggle();
      $(".sidebar--offer--custom li.have-children").removeClass("active");
      $(this).parent().addClass("active");
    } else {
      $(this).next().slideToggle();
      $(".sidebar--offer--custom li.have-children").removeClass("active");
    }

  });
  if ($("ul.submenu li.active")) {
    $(".submenu li.active").parent().parent("li").addClass("active");
    $(".sidebar--offer--custom > li.have-children.active a.action").next().slideToggle();
  }

});

$(function () {
  $('a[data-assent]').click(function (e) {
    e.preventDefault();
    var assent = parseInt($(this).attr('data-assent'));
    if ($(this).data("open") == 1) {
      $(this).data("open", 0);
      $(this).text('[ schowaj ]');
    } else {
      $(this).data("open", 1);
      $(this).text('[ rozwiń ]');
    }
    $('#showAssent' + assent).stop().slideToggle();
  });

  $('.subscribe-form .input').click(function () {
    $('.subscribe-form a[data-assent]').data("open", 0);
    $('.subscribe-form a[data-assent]').text('[ schowaj ]');
    $('#showAssent9').stop().slideDown()
  });

});

$(document).ready(function () {
  $('#navbarNavDropdown a.dropdown-toggle').click(function () {
  
  if (window.innerWidth <=1920) {
    return true;
  } else {
    return false;
  }
  });

var is_first = false;

function checkValid(field, valid) {

  if (is_first != false) {
    if (!is_first.next().hasClass('error')) {
      is_first = false;
    }
  } else {
    is_first = false;
  }

  var fieldObj = {
    min: field.attr('min'),
    max: field.attr('max'),
    error: field.next('.error'),
    length: field.val().length,
    value: field.val(),
    checked: field.prop('checked'),
  };
  var error = '';
  if (valid) {

    var validate = valid.split(" ");

    if (fieldObj.min) {
      if (fieldObj.min > fieldObj.length) error = 'Za mało znaków (minimum: ' + fieldObj.min + ')';
    }

    if (field.attr('type') == 'checkbox') {
      if (!fieldObj.checked && validate.indexOf('required') > -1) {
        field.parent('.assents').addClass('error-checked');
        error = 'Pole jest wymagane!';
      } else {
        field.parent('.assents').removeClass('error-checked');
      }
    }

    if (fieldObj.max) {
      if (fieldObj.max < fieldObj.length) error = 'Za dużo znaków (maksymalnie: ' + fieldObj.max + ')';
    }

    if (validate.indexOf('mail') > -1) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      (re.test(fieldObj.value)) ? '' : error = 'Mail jest nieprawidłowy!';
    }

    if (validate.indexOf('required') > -1) {
      if (fieldObj.length <= 0) error = 'Pole jest wymagane!';
    }


    fieldObj.error.remove();
    if (error != '') {
      if (is_first == false) is_first = field;
      field.after('<small class="text-danger error">' + error + '</small>');
      return false;
    } else {

    }
  }
  return true;
}

$('input').focusout(function () {
  checkValid($(this), $(this).attr('validate'));
});

$("form").submit(function (e) {
  is_first = false;


  $('input, select, textarea', this).each(
    function () {
      var input = $(this);
      var isValid = checkValid($(this), $(this).attr('validate'));

      if (isValid) {

        return;
      } else {

        $('body').scrollTop(is_first.offset().top);
        $('html, body').stop().animate({
          'scrollTop': is_first.offset().top - 100
        });
        e.preventDefault();
      }
    }
  );
});
}); 

let app = createApp({});

app.config.globalProperties.$filters = {
    render_price(p) {
        p = parseFloat(p).toFixed(2).replace(".", ",") + ' zł';
        return p;
    }
}


app.config.globalProperties.$filters = {
  render_price(p) {
      p = parseFloat(p).toFixed(2).replace(".", ",") + ' zł';
      return p;
  },

  formatDate(value){
    if (value) {
      const date = new Date(value);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
  
      return `${day}-${month}-${year} ${hours}:${minutes}`;
    }
  }
}
  


import { createApp } from 'vue';
import { store } from './store/index.js';
import cart_top from './components/cart-top.vue';
import cart from './components/cart.vue';
import addtocartbutton from './components/add-to-cart-button.vue';
import delivery from './components/delivery.vue';
import productaddtocart from './components/product-add-to-cart.vue';
import tableprice from './components/tableprice.vue';
import singleproductgallery from './components/single_product_gallery.vue';
import coupon from './components/coupon.vue';
import cart_total from './components/cart_total.vue';
import cart_next_buttons from './components/cart-next-buttons.vue';
import cart_steps from './components/cart-steps.vue';
import compare from './components/compare.vue';
// import { headroom } from 'vue-headroom'
app.use(store);
// app.component('headroom' , headroom);
app.component('cart_top' , cart_top);
app.component('cart' , cart);
app.component('addtocartbutton' , addtocartbutton);
app.component('delivery' , delivery);
app.component('productaddtocart' , productaddtocart);
app.component('singleproductgallery' , singleproductgallery);
app.component('tableprice' , tableprice);
app.component('coupon' , coupon);
app.component('cart_total' , cart_total);
app.component('cart_next_buttons' , cart_next_buttons);
app.component('cart_steps' , cart_steps);
app.component('compare' , compare);
app.mount('#app-cart')



app.config.productionTip = false;

import Swiper from 'swiper/dist/js/swiper.min';



let opcje = {};

if ( $(".swiper-container .swiper-slide").length == 1 ) {
  let opcje = {
      speed: 300,
      grabCursor: 1, 
      keyboardControl: 1,
      shortSwipes: 1, 
      autoplay: {
        disableOnInteraction: 0,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-prev-top",
        prevEl: ".swiper-next-top"
      },
    }
    // Slider - gĹowy slider  
var swiper = new Swiper(".slider-home", opcje);
} else {
  let opcje = {
        loop: false,
        watchOverflow: true,
        autoplay: false,
    }
    // Slider - gĹowy slider  
var swiper = new Swiper(".slider-home", opcje);
}
 








let opcje1 = {};
if ( $(".swiper-container .swiper_category").length == 1 ) {
  let opcje1 = {
    slidesPerView: 2,
    spaceBetween: 30,
    speed: 2000,
    grabCursor: 1, 
    autoplay: {
      delay: 6000,
    },
    navigation: {
      nextEl: ".swiper-button-next1",
      prevEl: ".swiper-button-prev1"
    },
    breakpoints: {
      768: {
        slidesPerView: 1,
      },
    },
  }
  // Slider - gĹowy slider  
  var category_swiper = new Swiper(".swiper_category", opcje1);
} else {
  let opcje1 = {
        loop: false,
        autoplay: false,
  }
  // Slider - gĹowy slider  
  var category_swiper = new Swiper(".swiper_category", opcje1);
}


var slider_small = new Swiper(".swiper-sidebar", {
  loop: 1,
  speed: 1000,
  grabCursor: 1,

  autoplay: {
    delay: 3000,
    disableOnInteraction: 0,
  },
 slidesPerView: 1,
  spaceBetween: 0,
  pagination: {
    el: '.swiper-pagination',
    clickable: 1,
  },
  navigation: {
    nextEl: ".swiper-prev",
    prevEl: ".swiper-next"
  },
});
var swiper_products = new Swiper(".swiper-recomended", {
  loop: 1,
  // speed: 1000,
  grabCursor: 1,

  spaceBetween: 20,
  // autoplay: {
  //   delay: 3000,
  //   disableOnInteraction: 0,
  // },
 slidesPerView: 3,
  pagination: {
    el: '.swiper-pagination',
    clickable: 1,
  },
  navigation: {
    nextEl: ".swiper-prev",
    prevEl: ".swiper-next"
  },
});
 




 




function sidebar() {
  var h = $('.headroom'), so = $('.sidebar--offer');
  if (so.html()) {
    setTimeout(() => {
      (h.hasClass('headroom--pinned') || so.offset().top >= $('.offer-products').offset().top && (!h.hasClass('headroom--unpinned')) ? so.css('top', '85px') : so.css('top', '10px'));
    }, 50);
  }
}
$( window ).scroll(function(){
  sidebar()
});


  $(document).ready(function () {
    ($('#invoice_label').html ? drop_box("#invoice_label", "#faktura_data") : '');
    ($('#comment_label').html ? drop_box("#comment_label", "#comment_box") : '');
    



  }); 

          function input_display(inp, box) {
            if ($(inp).is(':checked')) {
              $(box).css('display', 'block');
            } else {
              $(box).css('display', 'none');
            }
          }

          function drop_box(inp, box) {
            var label = inp.replace('#', "");
            $(inp).click(function () {
              input_display(inp, box);
            });
            input_display(inp, box);
            $(inp).addClass('clikable_box');
            // $(box).addClass('clikable_box');
            $('[for="' + label + '"]').addClass('clikable_box');
          }

          function toggle_scroll() {
            if ($("#bgShadow").hasClass("active")) {
              $("body").css('overflow', 'hidden');
            } else {
              $("body").css('overflow', '');
            };
          }
          
          function bodyAddPadding() {
            $("body").css('padding-right', '15px');
          };

$(document).ready(function () {

  /* Add to cart product  */
  jQuery("#have-acc").click(function () { 
    $("#popup-login").show("slow");
    $("#bgShadow").addClass("active");
    toggle_scroll();
    bodyAddPadding();
  });

  
  jQuery("#close-login-popup").click(function () {
    // $("#popup-login").addClass("d-none");
    $("#popup-login").hide();
    $("#bgShadow").removeClass("active");
    toggle_scroll();
    bodyRemovePadding();
    toggle_scroll();
  });

  jQuery('[data-id="popup-login"]').click(function () {
    // $("#popup-login").addClass("d-none");
    $("#popup-login").hide("slow");
    $("#bgShadow").removeClass("active");
    toggle_scroll();
    bodyRemovePadding();
    toggle_scroll();
  });
  
})


$(document).ready(function(){ 
  // if($('.rellax').length)
  //   new Rellax('.rellax'); 
  /* 1. Visualizing things on Hover - See next part for action on click */
  $('#stars li').on('mouseover', function(){
    var onStar = parseInt($(this).data('value'), 11); // The star currently mouse on
   
    
    // Now highlight all the stars that's not after the current hovered star
    $(this).parent().children('li.star').each(function(e){
      if (e < onStar) {
        $(this).addClass('hover');
      }
      else {
        $(this).removeClass('hover');
      }
    });
    
  }).on('mouseout', function(){
    $(this).parent().children('li.star').each(function(e){
      $(this).removeClass('hover');
    });
  });
  

  /* 2. Action to perform on click */
  $('#stars li').on('click', function(){
    var onStar = parseInt($(this).data('value'), 10); // The star currently selected
    var stars = $(this).parent().children('li.star'); 
    for (let i = 0; i < stars.length; i++) {
      $(stars[i]).removeClass('selected');
    }
    
    for (let i = 0; i < onStar; i++) {
      $(stars[i]).addClass('selected');
    }

    $('[name="rating"]').val(onStar);
    
  });

});



$('#show_price').on("click", function(){
  $('#myTab li a').removeClass('active');
  $('#cennik-tab').addClass('active');
  
  $('#myTabContent div[role="tabpanel"]').removeClass('active show');
  $('#cennik').addClass('active show');

  
  var item = $('#cennik-tab').first().offset().top;
  jQuery("html, body").animate({
    scrollTop: item
  }, 500);
})


function slider_height() {
  $('.single-product .swiper-slide').css('height', $('.gallery-top').width() * 2 / 3 +'px');
}


function height_error(){
  var height = $(window).height();
  var top_bar = $('.top_bar').innerHeight()
  $('.error404').height(height-top_bar)
}

$(document).ready(function ($) { 
  $("select").chosen({
    "disable_search": true
  });
  slider_height()
  
  height_error();
  $( window ).resize(function() {
    height_error();
    slider_height()
  });

});


jQuery('a').click(function(e){
  if(e.target.hash && !$('body').hasClass("moje-konto/zamowienia")) {
    var target = e.target.hash;
    var n = target.includes("#");
    if(n && !e.currentTarget.classList.contains('not-scroll') && !$(e.target).hasClass('nav-link')) { 
      var item = jQuery(target)[0].offsetTop;
      jQuery("html, body").animate({
        scrollTop: item
      }, 500);
    }
  }
}) 
 


//search by price helper
$('.advance').on('change keyup blur', function(e){ 
  var id_arr = $(this).attr('id');
	var id = id_arr.split("_");
  var fullPay = $('#fullPayment_'+id[1]).val();
  var advancePay = $('#advancePayment_'+id[1]).val();
    if ($(this).val() > parseInt(fullPay)) {
       e.preventDefault();     
       $(this).val(fullPay);
    }
});
// import moment from 'moment';
























































// NOWY ONYX


import Headroom from "headroom.js";

var header = document.querySelector("header");

var options_headeroom = {
  offset: $('body').hasClass('headroom') ? 65 : 0
}

var headroom1 = new Headroom(header, options_headeroom);
headroom1.init();



var swiper= Swiper;
var init = false;
/* Which media query
**************************************************************/

var swiper = new Swiper(".swiper_offers", {
  slidesPerView: 3,
  spaceBetween: 10,
  // spaceBetween: 10,
  // slidesPerColumn: 2,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    // when window width is >= 320px
    100: {
      loopFillGroupWithBlank: false, 
      grabCursor: 1,
      spaceBetween: 10,
      // centeredSlides: 0,
      // sliderPerColumnFill: 'row',
      // centeredSlides:0,
      // spaceBetween: 10,
      slidesPerView: 1,
      // slidesPerColumn: 2,
      grid: {
        fill: 'row',
        rows: 2,
      },
    },
    768: {
      loopFillGroupWithBlank: false, 
      grabCursor: 1,
      spaceBetween: 10,
      // centeredSlides: 0,
      // sliderPerColumnFill: 'row',
      // centeredSlides:0,
      // spaceBetween: 10,
      slidesPerView: 2,
      // slidesPerColumn: 2,
      grid: {
        fill: 'row',
        rows: 2,
      },
    },
    992: {
      slidesPerView: 2,
      spaceBetween: 10
    }
  },
  navigation: {
    nextEl: '.next',
    prevEl: '.prev',
  },
});



function initSwipers(){
  var mySwiper8 = new Swiper(".swiper_best", {
    slidesPerView: 4,
    spaceBetween: 10,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: '.next',
      prevEl: '.prev',
    },
    breakpoints: {
      768: {
        loopFillGroupWithBlank: false, 
        grabCursor: 1,
        spaceBetween: 10,
        slidesPerView: 2,
        grid: {
          fill: 'row',
          rows: 2,
        },
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 10
      }
    },
  });
}
initSwipers()
$('.reinit_swiper').on('click', function(){
  console.log('reinit')
  setTimeout(function(){
    $(".swiper_best").each(function(){
      this.swiper.destroy();
    });
    initSwipers()
  }, 300) 
})




var swiper = new Swiper(".mySwiper1", {
  slidesPerView: 3,
  spaceBetween: 0,
  centeredSlides: 1,
  loop: 1
});
var swiper2 = new Swiper(".swiper_home", {
  slidesPerView: 1,
  spaceBetween: 0,
  centeredSlides: 1,
  loop: 1,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});

var slides = document.querySelectorAll('.swiper-recommended .swiper-slide');
      var swiper;
      if (slides.length > 1) {
var swiper3 = new Swiper(".swiper-recommended", {
  slidesPerView: 1,
  spaceBetween: 0,
  loop: 1,
  navigation: {
    nextEl: '.button-next',
    prevEl: '.button-prev',
  },
});

}
















































































  $(document).ready(function(){
    filtry()
    $(window).resize(function() {
      filtry()
  });
  function filtry() {
    if ($(window).width() >= 1200)
      {
        $('#filtry').attr("data-toggle", "");
        $('#collapseExample3').addClass('show');
      }
      else
      {
        $('#filtry').attr("data-toggle", "collapse");
        $('#collapseExample3').collapse('hide');
      }
  }


    $('#collapseExample2').on('shown.bs.collapse hidden.bs.collapse', function () {
      if ($('#show_params').hasClass('collapsed')) {
        $('#show_params').html('ROZWIŃ');
      } else {
        $('#show_params').html('ZWIŃ');
      }
    });
    
    

    $('#sort_form').on('change', function(e){ 
      $('#get_sort').val($('#sort_form option:selected').val());
      $('#products_filter input:first').trigger('change');
    })

		var delayTimer;
		$('#products_filter input').on('change', function(e){  
			console.log('wyszukiwarka na ofercie') 
			e.preventDefault();
			// ajaxLoad(); 
			clearTimeout(delayTimer);

			$('#loading_products').fadeIn();
			delayTimer = setTimeout(ajaxLoad, 1500);
		});
		  $('.page_changer').on('click', function(e){
				ajaxLoad(e.target.dataset.page); 
			})

			$('.page_change_by_number').on('change', function(e) {
				setTimeout(function() {
					if(parseInt(e.currentTarget.value) > parseInt(e.currentTarget.max)) {
						ajaxLoad(e.currentTarget.max);
					} else {
						ajaxLoad(e.currentTarget.value);
					}
				}, 400); 
			})
		});
		
	  function changeUrl() {
		var search = window.location.search;
		$('.product-list-left-menu a').attr('href')+search;
		$( ".product-list-left-menu ul li a" ).each(function( index ) {
		  var oldUrl = $(this).attr("href"); // Get current url
		  var url = oldUrl.split("?");
		  var newUrl = url[0]+search // Create new url\
		  $(this).attr("href", newUrl); // Set herf value
		});
	  }
	  
	  function ajaxLoad(page = 1) {
		var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');


		var search = $('#name_search').val();



		// var category = $('input[name="category"]:checked').serializeArray();
		// let ids_category = category.map(a => a['value']);
		var fuels = $('input[name="fuel_type"]:checked').serializeArray();
		var gearboxs = $('input[name="gearbox"]:checked').serializeArray();
		var marks = $('input[name="mark"]:checked').serializeArray();
		var categories = $('input[name="category"]:checked').serializeArray();
		let fuel_type = fuels.map(a => a['value']);
		let gearbox = gearboxs.map(a => a['value']);
		let mark = marks.map(a => a['value']);
		let category = categories.map(a => a['value']);
    let min_range = $('input[name="min_range"]').val();
    let max_range = $('input[name="max_range"]').val();
		// var producent = $('input[name="producent"]:checked').serializeArray();
		// let ids_producent = producent.map(a => a['value']);
		
		// var max_range = $('input[name="max_range"]').val();

		// var min_range = $('input[name="min_range"]').val();
		// var max_range = $('input[name="max_range"]').val();
		

		var sort = $('select[name="sort"] option:selected').val();

		// var volume = $('input[name="option_0"]:checked').serializeArray();
		// let range_volume = volume.map(a => a['value']);

		// var caliber = $('input[name="option_1"]:checked').serializeArray();
		// let range_caliber = caliber.map(a => a['value']);

		// var shots = $('input[name="option_2"]:checked').serializeArray();
		// let range_shots = shots.map(a => a['value']);

		// var proste_katowe = $('input[name="option_3"]:checked').serializeArray();
		// let range_proste_katowe = proste_katowe.map(a => a['value']);
		// let multikaliber = ($('input[name="option_4"]:checked').val()) ? $('input[name="option_4"]:checked').val() : '';
 


		// let available = ($('input[name="available"]:checked').val()) ? 1 : 0;
		// let ciche = ($('input[name="ciche"]:checked').val()) ? 1 : 0;

		  $.ajax({
			type: "POST",
			url: window.location.pathname,
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			},
			data: {
				search: search,
				sort: sort,
        page: page,
				min_range: min_range,
				max_range: max_range,
				fuel_type: fuel_type.join(", "),
				gearbox: gearbox.join(", "),
				mark: mark.join(", "),
				category: category.join(", "),
			},
			  success: function (data) {
				  $("#contents").html(data); 
				  $('#category_name').html('Wyniki wyszukiwania:')
				  $('#loading_products').fadeOut();
				  
			$('.page_changer').on('click', function(e){
				ajaxLoad(e.target.dataset.page); 
			})
			$('.page_change_by_number').on('change', function(e) {
				setTimeout(function() {
					if(parseInt(e.currentTarget.value) > parseInt(e.currentTarget.max)) {
						ajaxLoad(e.currentTarget.max);
					} else {
						ajaxLoad(e.currentTarget.value);
					}
				}, 400); 
    		})

				  // if(max_range != '' || max_range != 5000 && name != '')   
				  $('#abort-all-ajax').removeClass('hidden'); 
				  window.history.pushState('test', $('title').html(), `${window.location.pathname}?&search=${search}&category=${category}&fuel_type=${fuel_type}&gearbox=${gearbox}&mark=${mark}&min_range=${min_range}&max_range=${max_range}&sort=${sort}&page=${page}`);
				  changeUrl();       
				  $("#contents").animate({
					opacity: 1, 
				  }, 300, function() {
					// Animation complete.
				  });

          if(!$('#clear_filter').length) {
            $('#filtry').after(`<div class="text-nowrap text-xs" id="clear_filter"><a href="${location.pathname}">WYCZYŚĆ FILTRY</a></div>`);
          }

	   
				  $('html, body').animate({
					scrollTop: $("#contents").offset().top-100
				  }, 800);
				  
				$('.spinner-border').addClass('hidden');
			  },
				error: function (xhr, status, error) {
				  
			  },
			});
	  };